/* global JSBridge */

const isInNative = () => {
  if (typeof JSBridge === 'undefined') { //⽼老老版本app，不不能使⽤用 JSBridge
    return false;
  } else {
    //新版本app，能够使⽤用 JSBridge
    return true;
  }
}

const getUserInfo = () => {
  if (isInNative()) {
    return JSON.parse(JSBridge.getData('userInfo'));
  } else {
    return null;
  }
}

const getDeviceInfo = () => {
  if (isInNative()) {
    return JSON.parse(JSBridge.getData('deviceInfo'));
  } else {
    return null;
  }
}

const setNativeTitle = (titleValue) => {
  if (!isInNative()) {
    return;
  }
  JSBridge.doAction('actionSetTitle', '{}', JSON.stringify({
    title: titleValue
  }));
}

const setShareContent = (options) => {

  if (!isInNative()) {
    return;
  }

  const titleValue = options.title;
  const contentValue = options.content;
  const urlValue = options.url;
  const imageUrlValue = options.imgUrl;

  JSBridge.doAction('actionShare', JSON.stringify({
    succeedCallback: 'nativeSucceedShare',
    failedCallback: 'nativeFailedCallback',
    canceledCallback: 'nattiveCanceledCallback'
  }), JSON.stringify({
    title: titleValue,
    content: contentValue,
    url: urlValue,
    imgUrl: imageUrlValue,
    channel: 14, // 微信(2) + 朋友圈(4) + 微博(8) }));
  }));
}

const forbidSwipe = () => {
  if (!isInNative()) {
    return;
  }
  JSBridge.doAction('permitSwipe', JSON.stringify({}), JSON.stringify({ canSwipe: '0' }));
}

forbidSwipe();

export default {
  isInNative,
  getUserInfo,
  getDeviceInfo,
  setNativeTitle,
  setShareContent
}