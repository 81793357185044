import axios from 'axios' // 注意先安装哦
import QS from 'qs' // 序列化请求数据，视服务端的要求
import Tools from './tools'

// let baseURL = 'https://h5.test.manyuejia.com.cn'
let baseURL = 'https://h5.manyuejia.com.cn'

let ENV = process.env.BUILD_ENV || 'local'
let ENV_ROOT_PATH = '/'

if (ENV === 'local') {
  baseURL = 'http://localhost:3000'
} else if (ENV === 'beta') {
  baseURL = 'https://h5.test.manyuejia.com.cn'
}

axios.defaults.baseURL = baseURL
axios.defaults.timeout = 30 * 1000

// request 拦截器
axios.interceptors.request.use(
  (config) => {
    //维护登录状态
    const token = ''
    token && (config.headers.Authorization = token)
    return config
  },
  (error) => {
    // 请求错误时做些事(接口错误、超时等)
    // Tip: 4
    // 关闭loadding
    console.log('request:', error)

    //  1.判断请求超时
    if (
      error.code === 'ECONNABORTED' &&
      error.message.indexOf('timeout') !== -1
    ) {
      console.log(
        '根据你设置的timeout/真的请求超时 判断请求现在超时了，你可以在这里加入超时的处理方案',
      )
      console.error(`加载超时，请检查网络`)
      // return service.request(originalRequest);//例如再重复请求一次
    }
    //  2.需要重定向到错误页面
    const errorInfo = error.response
    console.log(errorInfo)
    if (errorInfo) {
      const errorStatus = errorInfo.status // 404 403 500 ... 等
    }
    return Promise.reject(error) // 在调用的那边可以拿到(catch)你想返回的错误信息
  },
)

// response 拦截器
axios.interceptors.response.use(
  (response) => {
    let data
    // IE9时response.data是undefined，因此需要使用response.request.responseText(Stringify后的字符串)
    if (response.data == undefined) {
      data = response.request.responseText
    } else {
      data = response.data
    }
    return data
  },
  (err) => {
    if (err && err.response) {
      switch (err.response.status) {
        case 400:
          err.message = '请求错误'
          break

        case 401:
          err.message = '未授权，请登录'
          break

        case 403:
          err.message = '拒绝访问'
          break

        case 404:
          err.message = `请求地址出错: ${err.response.config.url}`
          break

        case 408:
          err.message = '加载超时，请检查网络'
          break

        case 500:
          err.message = '服务器内部错误'
          break

        case 501:
          err.message = '服务未实现'
          break

        case 502:
          err.message = '网关错误'
          break

        case 503:
          err.message = '服务不可用'
          break

        case 504:
          err.message = '网关超时'
          break

        case 505:
          err.message = 'HTTP版本不受支持'
          break

        default:
      }
    }
    console.error(err)
    //  1.判断请求超时
    if (err.code === 'ECONNABORTED' && err.message.indexOf('timeout') !== -1) {
      err.message = `加载超时，请检查网络`
      // return service.request(originalRequest);//例如再重复请求一次
    }
    console.error(`${err.message}`)
    return Promise.reject(err) // 返回接口返回的错误信息
  },
)

function getMockUrl(url) {
  let r = Tools.getQuery(url)['r']
  let requestUrl = url
  r = r.replace('/', '_')
  requestUrl = window.location.origin + ENV_ROOT_PATH + '/mocks/' + r + '.json'
  return requestUrl
}

/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
function get(url, params, isMock = false) {
  let requestUrl = url
  if (isMock) {
    requestUrl = getMockUrl(url)
  }
  return new Promise((resolve, reject) => {
    axios
      .get(requestUrl, {
        params: params,
      })
      .then((res) => {
        resolve(res)
      })
      .catch((err) => {
        reject(err)
      })
  })
}
/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
function post(url, params, isMock = false) {
  let requestUrl = url
  if (isMock) {
    requestUrl = getMockUrl(url)
    return new Promise((resolve, reject) => {
      axios
        .get(requestUrl, {
          params: params,
        })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  } else {
    return new Promise((resolve, reject) => {
      axios
        .post(url, QS.stringify(params))
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}
/**
 * post方法，json请求格式的
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
function postJSON(url, params, isMock) {
  let requestUrl = url
  if (isMock) {
    requestUrl = getMockUrl(url)
    return new Promise((resolve, reject) => {
      axios
        .get(requestUrl, {
          params: params,
        })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  } else {
    return new Promise((resolve, reject) => {
      axios({
        url: url,
        method: 'POST',
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
        data: params,
      })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}

function postFile(url, data) {
  return new Promise((resolve, reject) => {
    axios({
      url: url,
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: data,
    })
      .then((res) => {
        resolve(res)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export default {
  ENV,
  ENV_ROOT_PATH,
  baseURL,
  get,
  post,
  postJSON,
  postFile,
}
