/*global GLOBAL*/

import React, { useState, useEffect } from 'react'
import * as echarts from 'echarts'
import http from '../util/http'
import tools from '../util/tools'
import { getEchartOptionsByWeightList } from '../components/buss'
import './App.scss'

import bannerImg from '../img/banner.png'
import bgImg from '../img/bg-s.png'

// const wxShareOptions = {
//   shareData: {
//     title: '想要提升竞争力吗？考个在职研吧',
//     desc: '985，211大学任你选，学费最低1.2万起',
//     // url: Config.CURRENT_PATH + '/index.html?shareUserId=' + Util.userInfo.userId
//     imgUrl:
//       window.location.origin +
//       '/' +
//       window.location.pathname.split('/')[1] +
//       '/src/index/images/share.tiff',
//   },
// }
// tools.setWechatShare(wxShareOptions)

const App = (props) => {
  const [reportData, setReportData] = useState(null)
  const [startDateText, setStartDateText] = useState('')
  const [birthDateText, setBirthDateText] = useState('')

  const getPageData = async () => {
    const res = await http.get('/api/v1/report.json', {
      key: GLOBAL.GET.share_key,
    })
    return res
  }

  const formatDate = (datestring) => {
    const d = new Date(tools.fixDateFormatForIOS(datestring))
    const arr = d.toUTCString().split(' ')
    const monthText = arr[2]
    const dayText = arr[1] * 1 + 1
    const yearText = arr[3]
    return `${monthText}.${dayText}.${yearText}`
  }
  useEffect(() => {
    async function init() {
      const res = await getPageData()
      // const res = {
      //   code: 0,
      //   report: PAGE_DATA,
      // }
      if (res.code === 0) {
        setReportData(res.report)

        setBirthDateText(formatDate(res.report.baby_birthday))
        setStartDateText(formatDate(res.report.start_date))

        setTimeout(() => {
          // 宝妈减重，展示
          if (res.report.mom_weight < 0) {
            const momWeights = []
            res.report.mom_weights.map((item, index) => {
              const date = new Date(tools.fixDateFormatForIOS(item.date))
              const day = date.getDay()
              if (day === 1 || index === 0) {
                momWeights.push({
                  day: index + 1,
                  ...item,
                })
              }
            })
            const momOptions = getEchartOptionsByWeightList(momWeights)
            const momChart = echarts.init(document.getElementById('chart-mom'))
            momChart.setOption(momOptions)
          }

          // 宝宝增重，展示
          if (res.report.baby_weight > 0) {
            const babyOptions = getEchartOptionsByWeightList(
              res.report.baby_weights,
              'baby',
            )
            const babyChart = echarts.init(
              document.getElementById('chart-baby'),
            )
            babyChart.setOption(babyOptions)
          }
        })
      }
    }
    init()
  }, [])

  if (!reportData) return null

  const bgLeftBottom = () => (
    <img className="bg-decoration bg-left-bottom" src={bgImg} />
  )

  const bgRightTop = () => (
    <img className="bg-decoration bg-right-top" src={bgImg} />
  )

  const gradientTitle = (title) => (
    <div className="info-box-title">
      <div className="info-box-title-container">
        <div className="info-box-title-box">
          <div className="info-box-title-icon"></div>
          <div className="info-box-title-text">{title}</div>
        </div>
      </div>
    </div>
  )

  const happyMoments = (imgs) => {
    let html
    const defaultClass = 'happy-moments'
    const img = (url, key) => (
      <div
        className={defaultClass + '-img'}
        key={key}
        style={{
          backgroundImage: `url(${url})`,
        }}
      ></div>
    )

    const html1 = <div className={defaultClass}>{img(imgs[0])}</div>
    const html3 = (
      <div className={defaultClass}>
        <div className={defaultClass + '-flex'}>
          {img(imgs[0])}
          {img(imgs[1])}
        </div>
        <div className={defaultClass + '-full'}>{img(imgs[2])}</div>
      </div>
    )
    const htmlNormal = (
      <div className={defaultClass}>
        <div className={defaultClass + '-normal'}>
          {imgs.map((item, index) => img(item))}
        </div>
      </div>
    )
    if (imgs.length === 1) {
      html = html1
    } else if (imgs.length === 3) {
      html = html3
    } else {
      html = htmlNormal
    }

    return html
  }

  const weightText = reportData.mom_weight > 0 ? '增长' : '下降'
  const weightNum = Math.floor(Math.abs(reportData.mom_weight))

  const waistText = reportData.mom_waistline > 0 ? '上升' : '减少'
  const waistNum = Math.floor(Math.abs(reportData.mom_waistline))

  return (
    <div className="app page-index">
      <div className="banner">
        <img src={bannerImg} alt="" />
        <div className="date">
          {reportData.start_date && reportData.start_date.replace(/-/g, '/')}
          <span>—</span>
          {reportData.start_date && reportData.end_date.replace(/-/g, '/')}
        </div>
      </div>
      <div className="bg">
        <div className="info-box info-box-01">
          <div className="info-box-inner">
            <div
              className="cover"
              style={{
                backgroundImage: `url(${reportData.share_cover_image})`,
              }}
            ></div>
            <div className="info-box-text">
              <p>{birthDateText}</p>
              <p>
                了不起的宝妈&nbsp;
                <span className="highlight">{reportData.nickname}</span>
              </p>
              {/* <p>
              经历了坚强的&nbsp;
              <span className="highlight">8小时</span>
            </p> */}

              <div className="summary">
                <p>生下可爱的</p>
                <p className="highlight">
                  {reportData.baby_sex === '男' ? '小王子' : '小公主'}
                </p>
              </div>
            </div>
            {bgLeftBottom()}
          </div>
        </div>

        <div className="info-box info-box-02">
          <div className="info-box-inner">
            {gradientTitle('安心月子')}
            <div
              className="cover"
              style={{
                backgroundImage: `url(${reportData.share_room_image})`,
              }}
            ></div>
            <div className="info-box-text">
              <p>{startDateText}</p>
              <p>
                入住
                <span className="highlight">“满悦家”</span>
                开启{reportData.days}天的调理
              </p>
              <p>
                在<span className="highlight">专属</span>责任护士和月嫂照料下
              </p>
              <p>
                完成
                <span className="highlight">
                  {reportData.nursing_times || 20}
                </span>
                次产康护理 及<span className="highlight">每周</span>专家看诊
              </p>

              {/* <div className="summary">
              <p>所有指标</p>
              <p className="highlight">非常健康</p>
            </div> */}
            </div>
            {bgLeftBottom()}
          </div>
        </div>

        {reportData.share_happy_moment &&
        reportData.share_happy_moment.length > 0 ? (
          <div className="info-box info-box-03">
            <div className="info-box-inner">
              {gradientTitle('幸福瞬间')}
              {happyMoments(reportData.share_happy_moment || [])}
              {bgRightTop()}
            </div>
          </div>
        ) : null}
        {reportData && reportData.mom_weight < 0 ? (
          <div className="info-box info-box-04">
            <div className="info-box-inner">
              {gradientTitle('我的战绩')}
              <div id="chart-mom"></div>

              <div className="info-box-desc">
                <p>经过{reportData.days}天的护理训练</p>
                <p>
                  我的体重{weightText}了
                  <span className="highlight">{weightNum}kg</span>
                  {reportData.mom_waistline < 0 ? (
                    <>
                      腰围{waistText}了
                      <span className="highlight">{waistNum}cm</span>
                    </>
                  ) : null}
                </p>
              </div>
            </div>
          </div>
        ) : null}

        {reportData &&
        reportData.baby_weight > 0 &&
        reportData.baby_weights.length > 0 ? (
          <div className="info-box info-box-05">
            <div className="info-box-inner">
              {gradientTitle('宝宝的成长')}
              <div id="chart-baby"></div>
              <div className="info-box-desc">
                <p>宝宝也在这里得到了很好的成长</p>
                <p>
                  从出生时的
                  <span className="highlight">
                    {Math.floor(reportData.baby_weights[0].weight)}g
                  </span>
                  已经涨到了
                  <span className="highlight">
                    {Math.floor(
                      reportData.baby_weights[
                        reportData.baby_weights.length - 1
                      ].weight,
                    )}
                    g
                  </span>
                </p>
              </div>
            </div>
          </div>
        ) : null}

        <div className="info-box info-box-06">
          <div className="info-box-inner">
            <div className="img-brand"></div>
            <h2>满悦家母婴护理中心</h2>
            <p className="grey">北正黄旗59号距地铁西郊线香山站入口步行410m</p>
            <img
              src="https://manyuejia-1302886832.file.myqcloud.com/wxapp/qr-code.jpg"
              alt=""
              className="qrcode"
            />
            <p className="scan">扫码关注</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default App
