// 根据体重记录生成echart配置
export const getEchartOptionsByWeightList = (list, type) => {
  if (!Array.isArray(list) || list.length === 0) return

  let defaultOptions = {
    unit: 'kg',
    min: 40,
    max: 100,
    interval: 20,
  }
  if (type === 'baby') {
    defaultOptions = {
      unit: 'g',
      min: 1500,
      max: 6000,
      interval: 1500,
    }
  }
  const defaultXLabelColor = 'rgba(170, 170, 170, 1)' // 默认x轴文字及水平方向线条颜色
  const defaultFontColor = '#FF3C77'

  // 获取最大最小值
  const getMaxAndMinWeightIndex = () => {
    let max = -1
    let min = -1
    list.map((item, index) => {
      if (max < 0) max = 0
      if (min < 0) min = 0
      if (item.weight >= list[max].weight) {
        max = index
      }
      if (item.weight <= list[min].weight) {
        min = index
      }
    })
    return {
      max,
      min,
    }
  }

  const extremeWeightIndex = getMaxAndMinWeightIndex()

  // 判断是否需要label
  // 需要的情况: 最大，最小，今日
  const needLabel = (index) => {
    return 0
    if (index === extremeWeightIndex.min) {
      return 1
    } else if (index === extremeWeightIndex.max) {
      return 2
    } else if (index === list.length - 1) {
      return 3
    }
    return 0
  }
  const getSeriesValue = (item, index) => {
    const needType = needLabel(index)
    if (needType > 0) {
      const labelOptions = {
        label: {
          show: true,
          position: needType === 1 ? 'bottom' : 'top',
          formatter: '{c}' + defaultOptions.unit,
        },
        symbolSize: 4,
      }
      return {
        value: item.weight,
        ...labelOptions,
      }
    } else {
      return item.weight
    }
  }

  const getDayLabel = (item, index) => {
    if (index === 0) {
      return '入所'
    } else if (item.day) {
      return item.day + '天'
    } else {
      return index + 1 + '天'
    }
  }

  // 生成数据
  const generateData = () => {
    const xData = []
    const yData = []
    list.map((item, index) => {
      xData.push(getDayLabel(item, index))
      yData.push(getSeriesValue(item, index))
    })

    return {
      x: xData,
      y: yData,
    }
  }

  const data = generateData()
  const echartOptions = {
    color: [defaultFontColor],
    xAxis: {
      type: 'category',
      boundaryGap: false,
      axisTick: { show: false, inside: true },
      axisLine: { show: false, inside: true },
      interval: 4,
      axisLabel: {
        show: true,
        color: defaultXLabelColor,
        fontSize: 10,
      },
      data: data.x,
    },
    yAxis: {
      type: 'value',
      show: true,
      boundaryGap: false,
      axisLine: {
        show: false,
        lineStyle: { color: defaultXLabelColor },
      },
      axisLabel: { show: false, inside: true },
      axisTick: { show: false, inside: true },
      min: defaultOptions.min,
      max: defaultOptions.max,
      interval: defaultOptions.interval,
    },
    // tooltip: {
    //   renderMode: 'richText',
    //   trigger: 'axis',
    //   formatter: '2021/08/21\n{c}kg',
    //   backgroundColor: '#fff',
    //   borderColor: '#000',
    //   borderWidth: 2,
    //   textStyle: {
    //     color: defaultFontColor,
    //     fontSize: 10,
    //     lineHeight: 12,
    //   },
    // },
    grid: {
      top: '0',
      left: '10px',
      right: '10px',
      bottom: '50px',
      backgroundColor: '#fff',
      width: 'auto', //图例宽度
      height: '153px', //图例高度
    },
    series: [
      {
        data: data.y,
        symbolSize: 0,
        type: 'line',
        smooth: true,
        areaStyle: {
          opacity: 0.8,
          color: {
            colorStops: [
              {
                offset: 0,
                color: '#fed4dd',
              },
              {
                offset: 1,
                color: '#fffbfc',
              },
            ],
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            type: 'linear',
          },
        },
      },
    ],
  }
  return echartOptions
}
