/* global MtaH5 */
/* global GLOBAL */
/* global GLOBAL_LOGGER wx */
import http from './http'
import native from './native'
// import Base64  from 'base-64';

const Tools = {
  isMobile: function (mobile) {
    return /^1[3456789][0-9]{9}$/.test(mobile)
  },
  isIOS() {
    return !!window.navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
  },
  isAndroid() {
    const u = window.navigator.userAgent
    return u.indexOf('Android') > -1 || u.indexOf('Linux') > -1
  },
  isWX: function () {
    let ua = window.navigator.userAgent
    return /MicroMessenger/gi.test(ua)
  },
  getOS() {
    // 获取当前操作系统
    let os
    if (
      navigator.userAgent.indexOf('Android') > -1 ||
      navigator.userAgent.indexOf('Linux') > -1
    ) {
      os = 'android'
    } else if (navigator.userAgent.indexOf('iPhone') > -1) {
      os = 'ios'
    } else if (navigator.userAgent.indexOf('Windows Phone') > -1) {
      os = 'wp'
    } else {
      os = 'others'
    }
    return os
  },

  getOSVersion() {
    // 获取操作系统版本
    let OSVision = '1.0'
    let isAndroid = this.isAndroid()
    let isIOS = this.isIOS()
    if (isAndroid) {
      OSVision = navigator.userAgent.split(';')[2].match(/\d+\.\d+/g)[0]
    }
    if (isIOS) {
      OSVision = navigator.userAgent
        .split(';')[1]
        .match(/(\d+)_(\d+)_?(\d+)?/)[0]
      OSVision = OSVision.replace('_', '.')
    }
    return OSVision
  },
  getQuery: function (href) {
    let url = href,
      request = {},
      strs
    if (url.indexOf('?') != -1) {
      let str = url.slice(
        url.indexOf('?') + 1,
        url.indexOf('#') >= 0 ? url.indexOf('#') : url.length,
      )
      strs = str.split('&')
      for (let i = 0; i < strs.length; i++) {
        let key = strs[i].split('=')[0]
        let value = strs[i].split('=')[1]
        request[key] = value
      }
    }
    return request
  },

  wechatShare: function (options) {
    //alert(JSON.stringify(options.shareToFriendData));
    options = options || {}
    options.shareData = options.shareData || {}
    options.shareToFriendData = options.shareToFriendData || options.shareData
    options.shareToTimelineData =
      options.shareToTimelineData || options.shareData
    options.shareToQQData = options.shareToQQData || options.shareData
    options.shareToWeiboData = options.shareToWeiboData || options.shareData
    options.shareCallback = options.shareCallback || function () {}

    //用户点击发送给朋友
    wx.onMenuShareAppMessage({
      title: options.shareToFriendData.title,
      desc: options.shareToFriendData.desc,
      link: options.shareToFriendData.url,
      imgUrl: options.shareToFriendData.imgUrl,
      trigger: function (res) {
        // alert(JSON.stringify(options.shareToFriendData));
        options.shareCallback.trigger &&
          options.shareCallback.trigger.apply(null, [res])
      },
      success: function (res) {
        //alert('已分享');
        options.shareCallback.success &&
          options.shareCallback.success.apply(null, [res])
      },
      cancel: function (res) {
        //alert('已取消');
        options.shareCallback.cancel &&
          options.shareCallback.cancel.apply(null, [res])
      },
      fail: function (res) {
        //alert(JSON.stringify(res));
        options.shareCallback.fail &&
          options.shareCallback.fail.apply(null, [res])
      },
    })

    //用户点击分享到朋友圈
    wx.onMenuShareTimeline({
      title: options.shareToTimelineData.title,
      link: options.shareToTimelineData.url,
      imgUrl: options.shareToTimelineData.imgUrl,
      trigger: function (res) {
        // alert('用户点击分享到朋友圈');
        // alert(JSON.stringify(options.shareToTimelineData));
        options.shareCallback.trigger &&
          options.shareCallback.trigger.apply(null, [res])
      },
      success: function (res) {
        //alert('已分享');
        options.shareCallback.success &&
          options.shareCallback.success.apply(null, [res])
      },
      cancel: function (res) {
        //alert('已取消');
        options.shareCallback.cancel &&
          options.shareCallback.cancel.apply(null, [res])
      },
      fail: function (res) {
        //alert(JSON.stringify(res));
        options.shareCallback.fail &&
          options.shareCallback.fail.apply(null, [res])
      },
    })

    //用户点击分享到微博
    wx.onMenuShareQQ({
      title: options.shareToQQData.title,
      desc: options.shareToQQData.desc,
      link: options.shareToQQData.url,
      imgUrl: options.shareToQQData.imgUrl,
      trigger: function (res) {
        //alert('用户点击分享到QQ');
        options.shareCallback.trigger &&
          options.shareCallback.trigger.apply(null, [res])
      },
      success: function (res) {
        //alert('已分享');
        options.shareCallback.success &&
          options.shareCallback.success.apply(null, [res])
      },
      cancel: function (res) {
        //alert('已取消');
        options.shareCallback.cancel &&
          options.shareCallback.cancel.apply(null, [res])
      },
      fail: function (res) {
        //alert(JSON.stringify(res));
        options.shareCallback.fail &&
          options.shareCallback.fail.apply(null, [res])
      },
    })

    //用户点击分享到微博
    wx.onMenuShareWeibo({
      title: options.shareToWeiboData.title,
      desc: options.shareToWeiboData.desc,
      link: options.shareToWeiboData.url,
      imgUrl: options.shareToWeiboData.imgUrl,
      trigger: function (res) {
        //alert('用户点击分享到微博');
        options.shareCallback.trigger &&
          options.shareCallback.trigger.apply(null, [res])
      },
      success: function (res) {
        //alert('已分享');
        options.shareCallback.success &&
          options.shareCallback.success.apply(null, [res])
      },
      cancel: function (res) {
        //alert('已取消');
        options.shareCallback.cancel &&
          options.shareCallback.cancel.apply(null, [res])
      },
      fail: function (res) {
        //alert(JSON.stringify(res));
        options.shareCallback.fail &&
          options.shareCallback.fail.apply(null, [res])
      },
    })
  },
  /**
   * 线上
   * wx6b481bf6785dbd44
   * 3c7acf28eacb067a3025ac16b1e8c159
   *
   * 测试号
   * wx09f88a95d20bae56
   * 024b4677bc1f13dda35b52b45b4549a8
   */
  // wxConfig: {
  //   appId: "wx6b481bf6785dbd44",
  //   appSecret: "3c7acf28eacb067a3025ac16b1e8c159"
  // },
  getWXSignature: function (success, fail) {
    http
      .get(http.frontEducationURL + '?r=entrance-exam/wechat', {
        //eslint-disable-next-line
        url: window.location.href.split('#')[0],
        userId: GET.userId,
        sessionKey: GET.sessionKey,
        // appId: Tools.wxConfig.appId,
        // appSecret: Tools.wxConfig.appSecret
      })
      .then((result) => {
        if (result.code === 0) {
          wx.config({
            debug: GET.isDebug, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: result.appId, // 必填，公众号的唯一标识
            timestamp: result.timestamp, // 必填，生成签名的时间戳
            nonceStr: result.nonceStr, // 必填，生成签名的随机串
            signature: result.signature, // 必填，签名，见附录1
            // jsApiList:['checkJsApi']
            jsApiList: [
              'checkJsApi',
              'onMenuShareTimeline',
              'onMenuShareAppMessage',
              'onMenuShareQQ',
              'onMenuShareWeibo',
              'hideMenuItems',
              'showMenuItems',
              'hideAllNonBaseMenuItem',
              'showAllNonBaseMenuItem',
              'translateVoice',
              'startRecord',
              'stopRecord',
              'onRecordEnd',
              'playVoice',
              'pauseVoice',
              'stopVoice',
              'uploadVoice',
              'downloadVoice',
              'chooseImage',
              'previewImage',
              'uploadImage',
              'downloadImage',
              'getNetworkType',
              'openLocation',
              'getLocation',
              'hideOptionMenu',
              'showOptionMenu',
              'closeWindow',
              'scanQRCode',
              'chooseWXPay',
              'openProductSpecificView',
              'addCard',
              'chooseCard',
              'openCard',
            ], // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
          })
          wx.ready(function () {
            console.log('wx.ready')
            success && success()
          })
        } else {
          fail && fail(result)
          // alert(result.msg);
        }
      })
      .catch((err) => {
        console.log(err)
      })
  },
  setWechatShare(wxShareOptions) {
    if (typeof wxShareOptions === 'undefined') {
      console.error('请传入分享配置')
      return
    }
    if (this.isWX()) {
      this.getWXSignature(() => {
        this.wechatShare(wxShareOptions)
      })
    } else {
      console.log('请在微信中打开')
    }
  },
  async logNews(newsId = '', userId = '', type = '') {
    const res = await http.post('?r=news/click-event', {
      newsId: newsId,
      userId: userId,
      type: type,
    })
    return res
  },
  async fixKeyboardCover() {
    // alert(window.navigator.appVersion);
    const os = this.getOS()
    const osVersion = this.getOSVersion()
    if (os === 'ios' && osVersion < 12) {
      setTimeout(function () {
        window.scrollTo(0, window.scrollY + 50)
      }, 200)
    }
  },
  logMtaEvent(eventName, options) {
    let opt = options || {}
    if (typeof MtaH5 !== 'undefined') {
      MtaH5.clickStat(eventName, opt)
    }
    GLOBAL_LOGGER.log({
      eventTypeName: 'click',
      en: eventName,
      ep: opt,
    })
  },
  fixVideoDragBug() {
    document.getElementById('root').addEventListener('click', () => {})
  },
  getBridge() {
    if (Tools.isAndroid()) {
      return window.android
    } else if (Tools.isIOS()) {
      return window
    } else {
      return null
    }
  },
  fixDateFormatForIOS(datestring) {
    return datestring.replace(' ', 'T').replace(/-/g, '/')
  },
  wrapFunctionWithPromise(fn) {
    return new Promise((resolve, reject) => {
      fn(resolve, reject)
    })
  },
}

const GET = Tools.getQuery(window.location.href)
Tools.GET = GET
Tools.Bridge = Tools.getBridge()
let userInfo = {
  userId: GET.userId || '',
  nickName: '匿名用户',
  avatar: '暂无头像',
}
if (native.isInNative()) {
  userInfo = native.getUserInfo()
  userInfo.avatar = '暂无头像'
}

Tools.USER_INFO = userInfo

export default Tools
